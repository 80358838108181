<template>
  <div class="container text-center">
    <template v-if="!state">
      <p class="my-4">{{ $t('upload.select-file') }}:</p>
      <b-row class="my-4 justify-content-center">
        <b-col md="6">
          <b-form-file
            v-model="file"
            :placeholder="$t('upload.choose-file') + '...'"
          ></b-form-file>
        </b-col>
      </b-row>
      <b-button @click="sendFile">{{ $t('upload.upload-button') }}</b-button>
    </template>
    <template v-else-if="state == 'error'">
      <p class="mt-4">{{ $t('upload.error') }}:</p>
      <pre class="text-left">{{ errors }}</pre>
    </template>
    <template v-else-if="state == 'processing'">
      <p class="mt-4">{{ $t('upload.processing-title') }}</p>
      <p>{{ $t('upload.processing-details') }}</p>
    </template>
    <template v-else-if="state == 'success'">
      <p class="mt-4">{{ $t('upload.success') }}:</p>
      <b-table hover striped :items="results" :fields="fields"></b-table>
    </template>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    state: String,
  },
  data() {
    return {
      file: null,
      errors: null,
      taskID: null,
      results: null,
      fields: ['#', 'external_id', 'job_id', 'error'],
    };
  },
  methods: {
    sendFile() {
      let formData = new FormData();
      formData.append('file', this.file);
      axios
        .post('/api/brand/orders/import', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          this.$router.replace('/upload/processing');
          this.taskID = response.data.task;
          this.startCheckStatus();
        })
        .catch((error) => {
          this.$router.replace('/upload/error');
          this.errors = error;
        });
    },
    startCheckStatus() {
      setTimeout(this.checkStatus, 3000);
    },
    checkStatus() {
      axios
        .get(`/api/tasks/${this.taskID}`)
        .then((response) => {
          if (response.data.completed) {
            this.$router.replace('/upload/success');
            this.results = response.data.result.results.map((e, idx) => {
              const row = { '#': idx + 1 };
              if (e.error) {
                row.error = e.error;
              } else {
                row.job_id = e.order.job__c.Id;
                row.external_id = e.order.job__c.eid || e.order.Account.eid;
              }
              return row;
            });
          } else {
            setTimeout(this.checkStatus, 3000);
          }
        })
        .catch((error) => {
          this.$router.replace('/upload/error');
          this.errors = error;
        });
    },
  },
};
</script>
